/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_CustomerService_Dtos_ParticipantBrokerAccount_ParticipantBrokerAccountDTO_Wealthlane_CustomerService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null } from '../models/Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_CustomerService_Dtos_ParticipantBrokerAccount_ParticipantBrokerAccountDTO_Wealthlane_CustomerService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null';
import type { Wealthlane_CustomerService_Dtos_ParticipantBrokerAccount_BrokerStatusDto } from '../models/Wealthlane_CustomerService_Dtos_ParticipantBrokerAccount_BrokerStatusDto';
import type { Wealthlane_CustomerService_Dtos_ParticipantBrokerAccount_CompanyListDto } from '../models/Wealthlane_CustomerService_Dtos_ParticipantBrokerAccount_CompanyListDto';
import type { Wealthlane_CustomerService_Dtos_ParticipantBrokerAccount_ParticipantListDto } from '../models/Wealthlane_CustomerService_Dtos_ParticipantBrokerAccount_ParticipantListDto';
import type { Wealthlane_CustomerService_Dtos_ParticipantBrokerAccount_ParticipantListRequestDto } from '../models/Wealthlane_CustomerService_Dtos_ParticipantBrokerAccount_ParticipantListRequestDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ParticipantBrokerAccountService {

    /**
     * @param companyIds 
     * @param participantIds 
     * @param countryIds 
     * @param stateIds 
     * @param brokerStatusIds 
     * @param filter 
     * @param sorting 
     * @param skipCount 
     * @param maxResultCount 
     * @returns Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_CustomerService_Dtos_ParticipantBrokerAccount_ParticipantBrokerAccountDTO_Wealthlane_CustomerService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null Success
     * @throws ApiError
     */
    public static getApiCustomerApiAppParticipantBrokerAccountParticipantList(
companyIds?: Array<string>,
participantIds?: Array<string>,
countryIds?: Array<string>,
stateIds?: Array<string>,
brokerStatusIds?: Array<number>,
filter?: string,
sorting?: string,
skipCount?: number,
maxResultCount?: number,
): CancelablePromise<Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_CustomerService_Dtos_ParticipantBrokerAccount_ParticipantBrokerAccountDTO_Wealthlane_CustomerService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customer/api/app/participant-broker-account/participant-list',
            query: {
                'CompanyIds': companyIds,
                'ParticipantIds': participantIds,
                'CountryIds': countryIds,
                'StateIds': stateIds,
                'BrokerStatusIds': brokerStatusIds,
                'Filter': filter,
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns string Success
     * @throws ApiError
     */
    public static postApiCustomerApiAppParticipantBrokerAccountExportParticipatList(
requestBody?: Wealthlane_CustomerService_Dtos_ParticipantBrokerAccount_ParticipantListRequestDto,
): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/customer/api/app/participant-broker-account/export-participat-list',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_CustomerService_Dtos_ParticipantBrokerAccount_CompanyListDto Success
     * @throws ApiError
     */
    public static getApiCustomerApiAppParticipantBrokerAccountCompanyList(): CancelablePromise<Array<Wealthlane_CustomerService_Dtos_ParticipantBrokerAccount_CompanyListDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customer/api/app/participant-broker-account/company-list',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param companyIds 
     * @returns Wealthlane_CustomerService_Dtos_ParticipantBrokerAccount_ParticipantListDto Success
     * @throws ApiError
     */
    public static getApiCustomerApiAppParticipantBrokerAccountParticipants(
companyIds?: Array<string>,
): CancelablePromise<Array<Wealthlane_CustomerService_Dtos_ParticipantBrokerAccount_ParticipantListDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customer/api/app/participant-broker-account/participants',
            query: {
                'CompanyIds': companyIds,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_CustomerService_Dtos_ParticipantBrokerAccount_BrokerStatusDto Success
     * @throws ApiError
     */
    public static getApiCustomerApiAppParticipantBrokerAccountBrokerStatusList(): CancelablePromise<Array<Wealthlane_CustomerService_Dtos_ParticipantBrokerAccount_BrokerStatusDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customer/api/app/participant-broker-account/broker-status-list',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
