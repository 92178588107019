import { type UseQueryResult, useQuery } from 'react-query';

import { type TableParamsType } from '../../types/common';
import {
  ParticipantBrokerAccountService,
  Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_CustomerService_Dtos_ParticipantBrokerAccount_ParticipantBrokerAccountDTO_Wealthlane_CustomerService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null,
} from '../../services/wealthlane-customer-services';

const useGetParticipantBrokerAccountParticipantList = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  tableInfo: TableParamsType,
  status?: number[],
  userStatus?: string
): UseQueryResult<
  // eslint-disable-next-line camelcase
  | Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_CustomerService_Dtos_ParticipantBrokerAccount_ParticipantBrokerAccountDTO_Wealthlane_CustomerService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null
  | undefined,
  unknown
> => {
  const {
    companyIds,
    participantIds,
    countryIds,
    stateIds,
    brokerStatusIds,
    filter,
    sorting,
    skipCount,
    maxResultCount,
  } = tableInfo;

  const participantBrokerAccountParticipantList = useQuery(
    [
      'getParticipantBrokerAccountParticipantList',
      companyIds,
      participantIds,
      countryIds,
      stateIds,
      brokerStatusIds,
      filter,
      sorting,
      skipCount,
      maxResultCount,
    ],
    async () => {
      return await handleRequest(
        ParticipantBrokerAccountService.getApiCustomerApiAppParticipantBrokerAccountParticipantList(
          companyIds,
          participantIds,
          countryIds,
          stateIds,
          brokerStatusIds,
          filter,
          sorting,
          skipCount,
          maxResultCount
        )
      );
    }
  );

  return participantBrokerAccountParticipantList;
};

export default useGetParticipantBrokerAccountParticipantList;
