/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Wealthlane_AwardSettlementService_Dtos_Disbursement_DisbursementCallbackRequestDto } from '../models/Wealthlane_AwardSettlementService_Dtos_Disbursement_DisbursementCallbackRequestDto';
import type { Wealthlane_AwardSettlementService_Dtos_Disbursement_DisbursementDetailDto } from '../models/Wealthlane_AwardSettlementService_Dtos_Disbursement_DisbursementDetailDto';
import type { Wealthlane_AwardSettlementService_Dtos_Disbursement_DisbursementStatusDto } from '../models/Wealthlane_AwardSettlementService_Dtos_Disbursement_DisbursementStatusDto';
import type { Wealthlane_AwardSettlementService_Dtos_Response_ResponseDto } from '../models/Wealthlane_AwardSettlementService_Dtos_Response_ResponseDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DisbursementService {

    /**
     * @param requestBody 
     * @returns boolean Success
     * @throws ApiError
     */
    public static postApiAwardSettlementApiAppDisbursementCallBackRequest(
requestBody?: Wealthlane_AwardSettlementService_Dtos_Disbursement_DisbursementCallbackRequestDto,
): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award-settlement/api/app/disbursement/call-back-request',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param transactionId 
     * @returns Wealthlane_AwardSettlementService_Dtos_Disbursement_DisbursementDetailDto Success
     * @throws ApiError
     */
    public static getApiAwardSettlementApiAppDisbursementDisbursementDetail(
transactionId: string,
): CancelablePromise<Wealthlane_AwardSettlementService_Dtos_Disbursement_DisbursementDetailDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award-settlement/api/app/disbursement/disbursement-detail/{transactionId}',
            path: {
                'transactionId': transactionId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param disbursementStatusId 
     * @param disbursementContainerStatusId 
     * @param requestBody 
     * @returns string Success
     * @throws ApiError
     */
    public static postApiAwardSettlementApiAppDisbursementExportDisbursement(
disbursementStatusId?: number,
disbursementContainerStatusId?: number,
requestBody?: Array<string>,
): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award-settlement/api/app/disbursement/export-disbursement',
            query: {
                'DisbursementStatusId': disbursementStatusId,
                'DisbursementContainerStatusId': disbursementContainerStatusId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardSettlementService_Dtos_Disbursement_DisbursementStatusDto Success
     * @throws ApiError
     */
    public static getApiAwardSettlementApiAppDisbursementDisbursementStatus(): CancelablePromise<Array<Wealthlane_AwardSettlementService_Dtos_Disbursement_DisbursementStatusDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award-settlement/api/app/disbursement/disbursement-status',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardSettlementService_Dtos_Disbursement_DisbursementStatusDto Success
     * @throws ApiError
     */
    public static getApiAwardSettlementApiAppDisbursementDisbursementContainerStatus(): CancelablePromise<Array<Wealthlane_AwardSettlementService_Dtos_Disbursement_DisbursementStatusDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award-settlement/api/app/disbursement/disbursement-container-status',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param formData 
     * @returns Wealthlane_AwardSettlementService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static postApiAwardSettlementApiAppDisbursementImportDisbursements(
formData?: {
File?: Blob;
},
): CancelablePromise<Wealthlane_AwardSettlementService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award-settlement/api/app/disbursement/import-disbursements',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
