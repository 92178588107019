import { type UseQueryResult, useQuery } from 'react-query';
import {
  ParticipantBrokerAccountService,
  VendorCompanyService,
} from '../../services/wealthlane-customer-services';

const useGetCompanyOptions = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseQueryResult<
  | Array<{
      label: string;
      value: string | number;
    }>
  | undefined,
  unknown
> => {
  const companyOptions = useQuery(
    ['getCompanyOptions'],
    async () => {
      return await handleRequest(
        ParticipantBrokerAccountService.getApiCustomerApiAppParticipantBrokerAccountCompanyList()
      );
    },
    {
      select: (data) => {
        if (data != null) {
          return data.map((info) => {
            return {
              label: info.companyName ?? '',
              value: info.id ?? '',
            };
          });
        }
      },
    }
  );

  return companyOptions;
};

export default useGetCompanyOptions;
