import { useEffect, useRef, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { ReactComponent as Logo } from '../../assets/svg/LogoHeader.svg';
import Badge from '../badge';
import {
  NavMenuContainer,
  NavMenuCon,
  NavMenuMobileContainer,
  ProfileDropdownContainer,
  ProfileDrop,
  ProfileDropdownMenu,
  DropDownMenuSuperAdmin,
  DropDownMenuAdmin,
  TaskSubMenuDropDown,
  MobileNavItem,
  ProfileDropWrapper,
  NavbarContainerMain,
} from './navbar.styled';
import { useSelector } from 'react-redux';
import { selectAuthRoutesList } from '../../pages/configuration/routeSlice';
import AuthService from '../../services/authService';
import { selectToken } from '../../auth/authSlice';
import Heading from '../typography/index';
import Notification from './notification';
import AnimationWrapper from '../animation/animationWrapper';
import NotificationModal from './notification/notificationModal';
import useWealthlaneNotificationApi from '../../hooks/useWealthlaneNotificationApi';
import Button from '../button';
// eslint-disable-next-line camelcase
import { type Wealthlane_NotificationService_DTOs_BellNotificationDto } from '../../services/wealthlane-notification-services';
import { useBoolean, useGetUnseenNotificationCount } from '../../hooks';
import { NavPermissionCheck } from '../../utils/global';
import { MyTaskRouteList } from '../../constants/innerRoutesList';
import CheckPermissions from '../../utils/checkPermission';

interface NavbarType {
  isAuth: boolean;
  // showNotify: boolean;
  // isNotify: boolean;
}

const Navbar = ({ isAuth }: NavbarType): JSX.Element => {
  // const dispatch = useDispatch();
  const wrapperRef: any = useRef<HTMLDialogElement>(null);
  const token = useSelector(selectToken);
  const userAuthList = useSelector(selectAuthRoutesList);
  const authService = new AuthService();
  const { handleRequest } = useWealthlaneNotificationApi();
  const { value: isModalOpen, setFalse: closeModal, setTrue: openModal } = useBoolean(false);
  const { data: NotificationCount } = useGetUnseenNotificationCount(handleRequest, token);

  const [openSideNav, setOpenSideNav] = useState(false);
  const [open, setOpen] = useState(false);
  const [isLoggedIn, setLoggedIn] = useState(true);
  // const [toggleAwards, setToggleAwards] = useState(false);
  const [newMessage] =
    // eslint-disable-next-line camelcase
    useState<Wealthlane_NotificationService_DTOs_BellNotificationDto>({});

  const allFieldValues = userAuthList.localization?.values?.Wealthlane;

  const [taskList, setTaskList] = useState(false);
  const onSideNavClick = (isClose?: boolean): void => {
    setOpenSideNav(!openSideNav);
    if (isClose ?? false) {
      document.getElementsByTagName('html')[0].style.overflow = 'unset';
    } else {
      document.getElementsByTagName('html')[0].style.overflow = 'hidden';
    }
  };

  useEffect(() => {
    function handleClickOutside(event: Event): void {
      if (
        Boolean(wrapperRef.current) &&
        !(wrapperRef.current as HTMLElement).contains(event.target as Node)
      ) {
        setOpenSideNav(false);
        // setToggleAwards(false);
        setTaskList(false);
        setOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  const filterMyTaskPermissionList = NavPermissionCheck(MyTaskRouteList);

  // useEffect(() => {
  //   if (showNotify) {
  //     document.body.style.paddingTop = '101px';
  //   } else {
  //     document.body.style.paddingTop = '67px';
  //   }
  // }, [showNotify]);

  // const storageEventHandler = () => {
  //   dispatch(setNotify({ notify: localStorage.getItem('Notify') === 'Yes' ? true : false }));
  // };

  // useEffect(() => {
  //   dispatch(setNotify({ notify: localStorage.getItem('Notify') === 'Yes' ? true : false }));
  //   window.addEventListener('storage', storageEventHandler, false);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // const handleRemoveNotify = () => {
  //   localStorage.setItem('Notify', 'No');
  //   dispatch(setNotify({ notify: false }));
  // };

  useEffect(() => {
    if (isModalOpen) setLoggedIn(false);
  }, [isModalOpen]);

  useEffect(() => {
    // if (isLoggedIn) {
    //   const connection = new signalR.HubConnectionBuilder()
    //     .withUrl(
    //       `${process.env.REACT_APP_WEALTHLANE_API_ENDPOINT}/api/notification/signalr-hubs/bellNotification`,
    //       {
    //         transport: signalR.HttpTransportType.LongPolling,
    //         accessTokenFactory: () => {
    //           return token || '';
    //         },
    //       }
    //     )
    //     .configureLogging(signalR.LogLevel.Information)
    //     .build();
    //   const start = async () => {
    //     try {
    //       await connection.start();
    //       console.log('SignalR Connected.');
    //     } catch (err) {
    //       setTimeout(start, 5000);
    //     }
    //   };
    //   connection.onclose(start);
    //   connection.on('ReceiveBellNotification', (user, message) => {
    //     refetch();
    //     setNewMessage(message);
    //   });
    //   // Start the connection.
    //   start();
    // }
  }, [isLoggedIn]);

  return (
    <>
      {/* {showNotify && (
        <HeaderNotice>
          <Heading
            variant='body-s'
            color={COLORS.Black}
            title='Need help? Call our team of specialists at 1-800-453-7544 between 9 a.m. and 6 p.m. ET.'
            className='notice-header'
          />
          <span
            className='material-symbols-outlined closeIcon'
            onClick={() => handleRemoveNotify()}
          >
            close
          </span>
        </HeaderNotice>
      )} */}
      <NavbarContainerMain hasNotify={false} className='nav'>
        <Link to='/'>
          <Logo className='nav-brand' />
        </Link>
        {isAuth && token?.length > 0 && (
          <>
            <div className='nav-mobile items-center'>
              <Notification
                NotificationCount={NotificationCount}
                isModalOpen={isModalOpen}
                openModal={() => {
                  openModal();
                }}
              />
              <Button
                title='Menu'
                variant='secondary'
                icon='menu'
                onClick={() => {
                  onSideNavClick(false);
                }}
                btnClassName='nav-menu'
              />
              <NavMenuMobileContainer className={openSideNav ? 'top-0' : '-top-[120%] opacity-0'}>
                <MobileNavItem className='static navMenuItem'>
                  <Heading variant='h6' title='Menu' className='nav-mobile--heading' />
                  <div className='nav-items'>
                    {/* <NavLink
                      to='/overview'
                      className={({ isActive }: any) => isActive && 'active p-2'}
                      onClick={() => onSideNavClick(true)}
                    >
                      <Heading variant='body' title='Overview' />
                    </NavLink> */}
                    <NavMenuCon className='static navMenuItem'>
                      <NavLink
                        to='/customer-list'
                        className={({ isActive }: { isActive: boolean }) =>
                          isActive ? 'active' : ''
                        }
                      >
                        Customers
                      </NavLink>
                    </NavMenuCon>
                    {/* <NavLink
                      to='/participants'
                      className={({ isActive }: any) => isActive && 'active p-2'}
                      onClick={() => onSideNavClick(true)}
                    >
                      <Heading variant='body' title='Participants' />
                    </NavLink> */}
                    <NavLink
                      to='/settings'
                      className={({ isActive }: { isActive: boolean }) =>
                        isActive ? 'active p-2' : ''
                      }
                      onClick={() => {
                        onSideNavClick(true);
                      }}
                    >
                      <Heading variant='body' title='Settings' />
                    </NavLink>
                  </div>
                </MobileNavItem>

                {filterMyTaskPermissionList && filterMyTaskPermissionList.length > 0 && (
                  <MobileNavItem className='static navMenuItem'>
                    <Heading variant='h6' title='My Task' className='nav-mobile--heading' />
                    <div className='nav-items'>
                      {filterMyTaskPermissionList.map((item) => (
                        <NavLink
                          to={item.url}
                          className={({ isActive }: any) => isActive && 'active p-2'}
                          onClick={() => onSideNavClick(true)}
                        >
                          <Heading variant='body' title={item.name} />
                        </NavLink>
                      ))}
                    </div>
                  </MobileNavItem>
                )}
                <MobileNavItem className='static navMenuItem'>
                  <Heading
                    variant='h6'
                    title={userAuthList?.currentUser?.name}
                    className='nav-mobile--heading'
                  />
                  <div className='nav-items'>
                    <Heading
                      variant='body'
                      title='Log Out'
                      onClick={async () => {
                        await authService.logout();
                      }}
                      className='cursor-pointer'
                    />
                  </div>
                </MobileNavItem>
                <div className='nav-close'>
                  <Button
                    title='Close'
                    variant='primary-dark'
                    icon='close'
                    onClick={() => {
                      onSideNavClick(true);
                    }}
                    btnClassName='nav-mobile--close'
                  />
                </div>
              </NavMenuMobileContainer>
            </div>
            <NavMenuContainer className='relative nav-main'>
              {/* <CheckPermissions type='View' urlName='overview'> */}
              {/* <NavMenuCon className='static navMenuItem'>
                <NavLink to='/overview' className={({ isActive }: any) => isActive && 'active'}>
                  Overview
                </NavLink>
              </NavMenuCon> */}
              <NavMenuCon className='static navMenuItem'>
                <NavLink
                  to='/customer-list'
                  className={({ isActive }: { isActive: boolean }) => (isActive ? 'active' : '')}
                >
                  Customers
                </NavLink>
              </NavMenuCon>
              {/* </CheckPermissions>
              <CheckPermissions type='View' urlName='rsu-awards'>
                {filterEquityAwardsList && filterEquityAwardsList.length > 0 && (
                  <div
                    className='flex items-center gap-x-2 relative'
                    onClick={() => setToggleAwards(!toggleAwards)}
                  >
                    <NavMenuCon className='static navMenuItem'>
                      <NavLink
                        to='/awards'
                        className={({ isActive }) =>
                          isActive ? 'active pointer-events-none' : 'pointer-events-none'
                        }
                      >
                        Awards Center
                      </NavLink>
                    </NavMenuCon>
                    {toggleAwards && (
                      <ProfileDropdownMenu ref={wrapperRef} className='nav-subMenu'>
                        {filterEquityAwardsList.map((item) => (
                          <DropDownMenuAdmin>
                            <NavLink to={item.url} onClick={() => setToggleAwards(!toggleAwards)}>
                              <h1 className='para' role='button'>
                                {item.name}
                              </h1>
                            </NavLink>
                          </DropDownMenuAdmin>
                        ))}
                      </ProfileDropdownMenu>
                    )}
                  </div>
                )}
              </CheckPermissions>
              <CheckPermissions type='View' urlName='participants'>
                <NavMenuCon className='static navMenuItem'>
                  <NavLink
                    to='/participants'
                    className={({ isActive }: any) => isActive && 'active'}
                  >
                    Participants
                  </NavLink>
                </NavMenuCon>
              </CheckPermissions> */}
              <CheckPermissions type='View' urlName='brokerage-import-export'>
               <NavMenuCon className='static navMenuItem'>
                <NavLink
                  to='/brokerage-import-export'
                  className={({ isActive }: { isActive: boolean }) => (isActive ? 'active' : '')}
                >
                  Brokerage Import & Export
                </NavLink>
              </NavMenuCon>
              </CheckPermissions>
              <NavMenuCon className='static navMenuItem'>
                <NavLink
                  to='/brokerage'
                  className={({ isActive }: { isActive: boolean }) => (isActive ? 'active' : '')}
                >
                  Brokerage Account
                </NavLink>
              </NavMenuCon>
              <NavMenuCon className='static navMenuItem'>
                <NavLink
                  to='/settings'
                  className={({ isActive }: { isActive: boolean }) => (isActive ? 'active' : '')}
                >
                  Settings
                </NavLink>
              </NavMenuCon>
            </NavMenuContainer>
            <ProfileDropdownContainer className='nav-main'>
              {Boolean(filterMyTaskPermissionList) && filterMyTaskPermissionList.length > 0 && (
                <div className='flex items-center gap-x-3 border-r-[3px] relative'>
                  <NavMenuCon
                    className='static navMenuItem'
                    onClick={() => {
                      setTaskList(!taskList);
                    }}
                  >
                    <Badge icon='task_alt' variant='primary' title='My Tasks' />
                  </NavMenuCon>
                  {taskList && (
                    <TaskSubMenuDropDown ref={wrapperRef}>
                      {filterMyTaskPermissionList.map((item) => {
                        // const findKey =
                        //   allFieldValues != null &&
                        //   Object.keys(allFieldValues).find((keyVal) =>
                        //     keyVal.includes(item.permissionName)
                        //   );
                        const findKey =
                          allFieldValues != null &&
                          Object.keys(allFieldValues).find((keyVal) =>
                            keyVal.includes(item.permissionName)
                          );

                        return (
                          <DropDownMenuAdmin>
                            <NavLink
                              to={item.url}
                              onClick={() => {
                                setTaskList(!taskList);
                              }}
                              className='w-full flex justify-start'
                            >
                              {findKey != null &&
                              findKey !== undefined &&
                              findKey !== false &&
                              allFieldValues !== undefined
                                ? allFieldValues?.[findKey] !== undefined && item?.name
                                : '-'}
                            </NavLink>
                          </DropDownMenuAdmin>
                        );
                      })}
                    </TaskSubMenuDropDown>
                  )}
                </div>
              )}
              <Notification
                NotificationCount={NotificationCount}
                isModalOpen={isModalOpen}
                openModal={() => {
                  openModal();
                }}
              />
              <ProfileDropWrapper>
                <ProfileDrop
                  onClick={() => {
                    setOpen(!open);
                  }}
                >
                  <div className='font-semibold title'>{userAuthList?.currentUser?.name}</div>
                  <span>
                    <span className='material-symbols-rounded downIcon'>arrow_drop_down</span>
                  </span>
                </ProfileDrop>
                {open && (
                  <ProfileDropdownMenu ref={wrapperRef}>
                    <Link
                      to='/security'
                      onClick={() => {
                        setOpen(false);
                      }}
                    >
                      <DropDownMenuSuperAdmin>Security</DropDownMenuSuperAdmin>
                    </Link>
                    <DropDownMenuSuperAdmin
                      className='logoutBtn'
                      onClick={() => {
                        void (async () => {
                          try {
                            await authService.logout();
                            // Perform any additional actions after successful logout
                          } catch (error) {
                            // Handle any errors that occur during logout
                          }
                        })();
                      }}
                    >
                      <h1 className='para' role='button'>
                        Logout
                      </h1>
                    </DropDownMenuSuperAdmin>
                  </ProfileDropdownMenu>
                )}
              </ProfileDropWrapper>
            </ProfileDropdownContainer>
          </>
        )}
      </NavbarContainerMain>
      <AnimationWrapper>
        {isModalOpen && (
          <NotificationModal
            handleRequest={handleRequest}
            isModalOpen={isModalOpen}
            token={token}
            newMessage={newMessage}
            closeModal={closeModal}
          />
        )}
      </AnimationWrapper>
    </>
  );
};

export default Navbar;
