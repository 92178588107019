// eslint-disable-next-line camelcase
import { type Wealthlane_CustomerService_Dtos_VendorCompany_CustomerCompayDetailViewForVendorDto } from '../../services/wealthlane-customer-services/models/Wealthlane_CustomerService_Dtos_VendorCompany_CustomerCompayDetailViewForVendorDto';
import { type UseQueryResult, useQuery } from 'react-query';
import { VendorCompanyService } from '../../services/wealthlane-customer-services';
import { type VendorCompanyViewData } from '../../types/returnTypes';
import { TaxIdFormat } from '../../utils/global';

const useGetVendorCompanyView = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  id?: string
): UseQueryResult<VendorCompanyViewData | undefined, unknown> => {
  const vendorCompanyView = useQuery(
    ['getVendorCompanyView', id],
    async () => {
      if (id !== null && id !== undefined) {
        return await handleRequest(
          VendorCompanyService.getApiCustomerApiAppVendorCompanyCompanyDetailForView(id)
        );
      }
    },
    {
      enabled: typeof id === 'string' && id !== '',
      select: (
        // eslint-disable-next-line camelcase
        data?: Wealthlane_CustomerService_Dtos_VendorCompany_CustomerCompayDetailViewForVendorDto
      ) => {
        if (data != null) {
          const payload = {
            companyStepOneForm: {
              name: data.companyName ?? '',
              ticker: data.ticker ?? '',
              industrySector: data.industrySector ?? '',
              industrySectorId: data.industrySectorId ?? '',
              taxId: data.taxId != null ? TaxIdFormat('taxId', data.taxId) : '',
              countryOfIncorporation: data.countryOfIncorporation ?? '',
              countryOfIncorporationId: data.countryOfIncorporationId ?? '',
              stateOfIncorporationText: data.stateOfIncorporation ?? '',
              stateOfIncorporationId: data.stateOfIncorporationId ?? '',
              primaryStockExchangeId: data.primaryStockExchangeId ?? '',
              primaryStockExchange: data.primaryStockExchange ?? '',
              dateOfIncorporation: data.dateOfIncorporation ?? '',
              businessDescription: data.businessDescription ?? '',
              websiteUrl: data.websiteUrl ?? '',
              domainNames: data.domainNames ?? '',
              headQuarterCityText: data.headQuarterCity ?? '',
              headQuarterStateText: data.headQuarterState ?? '',
              headQuarterCountry: data.headQuarterCountry ?? '',
              headQuarterCountryId: data.headQuarterCountryId ?? '',
              headQuarterAddressLine1: data.headQuarterAddressLine1 ?? '',
              headQuarterAddressLine2: data.headQuarterAddressLine2 ?? '',
              headQuarterStateId: data.headQuarterStateId ?? '',
              headQuarterZipCode: data.headQuarterZipCode ?? '',
              companyAddressList: data.companyAddresses ?? '',
              additionalAddress:
                (data.companyAddresses != null && data.companyAddresses?.length > 0) ?? '',
              cusip: data.cusip ?? '',
              sicCode: data.sicCode ?? '',
            },
            companyStepTwoForm: {
              ADMIN: {
                email: data.adminEmail ?? '',
                phone: data.adminPhone ?? '',
                firstName: data.adminFirstName ?? '',
                middleName: data.adminMiddleName ?? '',
                lastName: data.adminLastName ?? '',
                personId: data.adminPersonId ?? '',
                adminCountryCallingCodeId: data.adminCountryCallingCodeId ?? '',
                adminCountryCallingCodeText: data.adminCountryCallingCodeText ?? '',
              },
              // APPROVAL: {
              //   email: data.approverEmail ?? '',
              //   phone: data.approverPhone ?? '',
              //   firstName: data.approverFirstName ?? '',
              //   middleName: data.approverMiddleName ?? '',
              //   lastName: data.approverLastName ?? '',
              //   personId: data.approverPersonId ?? '',
              //   approverCountryCallingCodeId: data.approverCountryCallingCodeId ?? '',
              //   approverCountryCallingCodeText: data.approverCountryCallingCodeText ?? '',
              // },
              vendorDesignationUser: data.vendorDesignationUsers?.map((item) => {
                return {
                  vendorDesignationId: item.vendorDesignationId ?? '',
                  vendorDesignationName: item.vendorDesignationName ?? '',
                  vendorUsers: item.vendorUsers?.map((newData: any) => {
                    return {
                      vendorUserId: newData.vendorUserId ?? '',
                      firstName: newData.firstName ?? '',
                      middleName: newData.middleName ?? '',
                      lastName: newData.lastName ?? '',
                      email: newData.email ?? '',
                      phone: newData.phone ?? '',
                    };
                  }),
                };
              }),
              vendorDesignationUsers:
                data.vendorDesignationUsers != null && data.vendorDesignationUsers?.length > 0
                  ? data.vendorDesignationUsers?.map((item: any) => {
                      return {
                        roleId: item.vendorDesignationId ?? '',
                        vendorUserIds:
                          item.vendorUsers?.map((newData: any) => newData.vendorUserId) ?? [],
                      };
                    })
                  : [
                      {
                        roleId: '',
                        vendorUserIds: [],
                      },
                    ],
            },
          };

          return payload;
        }
      },
    }
  );

  return vendorCompanyView;
};

export default useGetVendorCompanyView;
